<template>
    <v-container class="custom-pricing mt-2 mt-md-4 full-height">
        <v-row justify="center" class="full-height">
            <v-col cols="12" lg="9" xl="7" class="full-height">
                <v-card
                    elevation="0"
                    class="top-border full-height"
                    :class="{ 'px-5 py-4 mb-5': $vuetify.breakpoint.mdAndUp }"
                >
                    <v-row class="ma-0">
                        <v-col v-if="link" class="pa-0">
                            <v-card-text class="text-body-1">
                                <p class="text-h5">Custom Pricing Link</p>
                                <p>
                                    This is your custom pricing link. You can
                                    share this link with your customers. Be sure
                                    to copy it and save it somewhere — it will
                                    not be saved after you leave this page.
                                </p>
                                <p>
                                    If you’d like to test it out, be sure to use
                                    your browser’s private browsing (or
                                    incognito) mode to see it exactly as your
                                    customer will.
                                </p>
                                <div class="link">
                                    <a :href="link" target="_blank">
                                        {{ link }}
                                    </a>
                                    <v-tooltip
                                        bottom
                                        :close-delay="linkCopied ? 2000 : 500"
                                        :nudge-right="linkCopied ? 20 : 0"
                                    >
                                        <template #activator="{ on, attrs }">
                                            <v-btn
                                                v-clipboard:copy="link"
                                                v-clipboard:success="
                                                    onLinkCopied
                                                "
                                                icon
                                                v-bind="attrs"
                                                v-on="on"
                                            >
                                                <v-icon small>
                                                    far fa-copy
                                                </v-icon>
                                            </v-btn>
                                        </template>
                                        <span>
                                            {{ copyTooltip }}
                                        </span>
                                    </v-tooltip>
                                </div>
                                <hr class="mt-8" />
                                <p class="mt-8">
                                    This is your custom pricing button. You can
                                    copy the code of it and reuse on your own
                                    HTML pages. Be sure to copy it and save it
                                    somewhere — it will not be saved after you
                                    leave this page.
                                </p>
                                <div class="link">
                                    <div id="button-code" class="pt-4 pr-4">
                                        <a :href="link">
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                height="56"
                                                viewBox="0 0 170 56"
                                            >
                                                <g
                                                    filter="url(#filter0_d_12967_3256)"
                                                >
                                                    <rect
                                                        x="4"
                                                        width="160"
                                                        height="48"
                                                        rx="8"
                                                        fill="#59748A"
                                                    />
                                                    <text
                                                        x="43"
                                                        y="30"
                                                        font-size="1.3em"
                                                        style="fill: white"
                                                    >
                                                        Buy now
                                                    </text>
                                                </g>
                                                <defs>
                                                    <filter
                                                        id="filter0_d_12967_3256"
                                                        x="0"
                                                        y="0"
                                                        width="195"
                                                        height="56"
                                                        filterUnits="userSpaceOnUse"
                                                        color-interpolation-filters="sRGB"
                                                    >
                                                        <feFlood
                                                            flood-opacity="0"
                                                            result="BackgroundImageFix"
                                                        />
                                                        <feColorMatrix
                                                            in="SourceAlpha"
                                                            type="matrix"
                                                            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                                            result="hardAlpha"
                                                        />
                                                        <feOffset dy="4" />
                                                        <feGaussianBlur
                                                            stdDeviation="2"
                                                        />
                                                        <feColorMatrix
                                                            type="matrix"
                                                            values="0 0 0 0 0.425 0 0 0 0 0.425 0 0 0 0 0.425 0 0 0 0.41 0"
                                                        />
                                                        <feBlend
                                                            mode="normal"
                                                            in2="BackgroundImageFix"
                                                            result="effect1_dropShadow_12967_3256"
                                                        />
                                                        <feBlend
                                                            mode="normal"
                                                            in="SourceGraphic"
                                                            in2="effect1_dropShadow_12967_3256"
                                                            result="shape"
                                                        />
                                                    </filter>
                                                </defs>
                                            </svg>
                                        </a>
                                    </div>
                                    <v-tooltip
                                        bottom
                                        :close-delay="buttonCopied ? 2000 : 500"
                                        :nudge-right="buttonCopied ? 20 : 0"
                                    >
                                        <template #activator="{ on, attrs }">
                                            <v-btn
                                                icon
                                                v-bind="attrs"
                                                @click="copyButtonCode"
                                                v-on="on"
                                            >
                                                <v-icon small>
                                                    far fa-copy
                                                </v-icon>
                                            </v-btn>
                                        </template>
                                        <span>
                                            {{ copyButtonTooltip }}
                                        </span>
                                    </v-tooltip>
                                </div>
                            </v-card-text>
                            <v-card-actions class="mx-2 pb-4 pt-8 justify-end">
                                <v-btn
                                    type="submit"
                                    color="primary"
                                    :block="$vuetify.breakpoint.smAndDown"
                                    @click="reset"
                                >
                                    Generate another link
                                </v-btn>
                            </v-card-actions>
                        </v-col>
                        <v-col v-else class="pa-0">
                            <a-form :submit="generate">
                                <v-card-text>
                                    <p class="text-h5">Custom Pricing Link</p>
                                    <p class="text-body-1">
                                        Create a custom offer using the options
                                        below. Then generate a unique link to
                                        this offer that you can share with your
                                        customers.
                                    </p>
                                    <p class="text-h6">
                                        Pricing Options for Selling Amps
                                    </p>
                                    <v-row class="form-row text-body-1">
                                        <v-col
                                            cols="12"
                                            md="auto"
                                            class="pr-0 mb-n4"
                                        >
                                            <a-toggle
                                                v-model="data.ch0_method"
                                                checkbox
                                                label="First Immediate Payment:"
                                                :messages="
                                                    immediatePaymentWarning
                                                "
                                            >
                                                <template #message>
                                                    <span class="red--text">
                                                        {{
                                                            immediatePaymentWarning
                                                        }}
                                                    </span>
                                                </template>
                                            </a-toggle>
                                        </v-col>
                                        <v-col
                                            cols="12"
                                            md="auto"
                                            class="form-row pt-0 pt-md-3 pl-1 pl-md-0"
                                        >
                                            <v-text-field
                                                v-model="data.ch0_amount"
                                                type="number"
                                                filled
                                                dense
                                                min="1"
                                                max="10000"
                                                hide-details
                                            />
                                            USD
                                        </v-col>
                                    </v-row>
                                    <v-row class="form-row text-body-1">
                                        <v-col cols="12" md="auto" class="pr-0">
                                            <a-toggle
                                                v-model="data.ch1_method"
                                                checkbox
                                                label="Subsequent One-Time Price:"
                                                hide-details
                                            />
                                        </v-col>
                                        <v-col
                                            cols="12"
                                            md="auto"
                                            class="form-row pt-0 pt-md-3 pl-1 pl-md-0 pr-0"
                                        >
                                            <v-text-field
                                                v-model="data.ch1_amount"
                                                type="number"
                                                filled
                                                dense
                                                min="1"
                                                max="10000"
                                                hide-details
                                            />
                                            {{ currency }}, billed
                                        </v-col>
                                        <v-col
                                            cols="12"
                                            md="auto"
                                            class="form-row pt-0 pt-md-3 pl-1 pl-md-0"
                                        >
                                            <v-text-field
                                                v-model="data.ch1_trial_days"
                                                type="number"
                                                filled
                                                dense
                                                min="1"
                                                max="31"
                                                hide-details
                                                @input="updateTrialDays"
                                            />
                                            days after initial payment
                                        </v-col>
                                    </v-row>
                                    <v-row class="form-row text-body-1">
                                        <v-col cols="12" md="auto" class="pr-0">
                                            <a-toggle
                                                v-model="data.ch2_method"
                                                checkbox
                                                label="Subscription Price:"
                                                hide-details
                                            />
                                        </v-col>
                                        <v-col
                                            cols="12"
                                            md="auto"
                                            class="form-row pt-0 pt-md-3 pl-1 pl-md-0 pr-0"
                                        >
                                            <v-text-field
                                                v-model="data.ch2_amount"
                                                type="number"
                                                filled
                                                dense
                                                min="1"
                                                max="10000"
                                                hide-details
                                            />
                                            {{ currency }} every month,
                                            beginning
                                        </v-col>
                                        <v-col
                                            cols="12"
                                            md="auto"
                                            class="form-row pt-0 pt-md-3 pl-1 pl-md-0"
                                        >
                                            <v-text-field
                                                v-model="data.ch2_trial_days"
                                                type="number"
                                                filled
                                                dense
                                                min="1"
                                                max="62"
                                                hide-details
                                                @input="updateTrialDays"
                                            />
                                            days after initial payment
                                        </v-col>
                                    </v-row>
                                </v-card-text>

                                <v-card-actions
                                    class="mx-2 pt-8 pb-4 justify-end"
                                >
                                    <v-btn
                                        type="submit"
                                        color="primary"
                                        :disabled="!canSubmit"
                                        :loading="isGenerating"
                                        :block="$vuetify.breakpoint.smAndDown"
                                    >
                                        Generate link
                                    </v-btn>
                                </v-card-actions>
                            </a-form>
                        </v-col>
                    </v-row>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script lang="ts">
import Component, { mixins } from 'vue-class-component';

import { Endpoint } from '@/mixins';

import { AForm } from '@/components/AForm';
import { AToggle } from '@/components/AForm/Inputs/AToggle';

import type { User } from '@/types/User';

interface CustomLinkData {
    host: string;
    redirect_location: string;
    ch0?: {
        method: string;
        amount: number;
    };
    ch1?: {
        method: string;
        amount: number;
        trial_days: number;
        interval_length: number;
        total_billing_cycles: number;
    };
    ch2?: {
        method: string;
        amount: number;
        trial_days: number;
    };
}

@Component({
    components: {
        AForm,
        AToggle
    }
})
export default class CustomPricing extends mixins(Endpoint) {
    endpoint = '/resellers/price_override_links';

    isGenerating = false;

    currency = 'USD';

    link = '';

    linkCopied = false;
    buttonCopied = false;

    data = {
        ch0_method: true,
        ch0_amount: 299,

        ch1_method: false,
        ch1_amount: 499,
        ch1_trial_days: 30,
        ch1_interval_length: 1,
        ch1_total_billing_cycles: 1,

        ch2_method: false,
        ch2_amount: 999,
        ch2_trial_days: 30
    };

    get canSubmit() {
        return (
            !this.isLoading &&
            Boolean(
                this.data.ch0_method ||
                    this.data.ch1_method ||
                    this.data.ch2_method
            )
        );
    }

    get copyTooltip() {
        return this.linkCopied ? 'Link Copied' : 'Click to Copy';
    }

    get copyButtonTooltip() {
        return this.buttonCopied ? 'Button Code Copied' : 'Click to Copy';
    }

    get immediatePaymentWarning() {
        if (!this.data.ch0_method) {
            return 'Unticking this box will give a free trial';
        }

        return null;
    }

    onLinkCopied() {
        this.linkCopied = true;
        setTimeout(this.resetCopy, 3000);
    }

    onData(data: { reseller: User }) {
        this.currency = data.reseller.reseller_currency;
    }

    updateTrialDays() {
        this.$nextTick(() => {
            if (this.data.ch2_trial_days < this.data.ch1_trial_days) {
                this.data.ch2_trial_days = this.data.ch1_trial_days;
            }
        });
    }

    async generate() {
        this.setUpdating();

        return this.sendLinkData()
            .then(({ data }) => {
                if (data.link) {
                    this.link = data.link;
                }
            })
            .finally(() => {
                this.setUpdating(false);
            });
    }

    async sendLinkData() {
        return this.$http
            .post(this.endpoint, this.getLinkData())
            .then(({ data }) => data)
            .catch(error => {
                if (!error.isIntercepted) {
                    this.$store.dispatch('notification/error', error);
                }
            });
    }

    getLinkData() {
        const data: CustomLinkData = {
            host: window.location.host,
            redirect_location: 'fast'
        };

        if (this.data.ch0_method) {
            data.ch0 = {
                method: 'upsale',
                amount: this.data.ch0_amount
            };
        }

        if (this.data.ch1_method) {
            data.ch1 = {
                method: 'split-payment',
                amount: this.data.ch1_amount,
                trial_days: this.data.ch1_trial_days,
                interval_length: this.data.ch1_interval_length,
                total_billing_cycles: this.data.ch1_total_billing_cycles
            };
        }

        if (this.data.ch2_method) {
            data.ch2 = {
                method: 'subscribe-new-plan',
                amount: this.data.ch2_amount,
                trial_days: this.data.ch2_trial_days
            };
        }

        return data;
    }

    reset() {
        this.link = '';
    }

    resetCopy() {
        this.linkCopied = false;
        this.buttonCopied = false;
    }

    copyButtonCode() {
        const elm = document.getElementById('button-code');

        if (elm) {
            this.$copyText(elm.innerHTML).then(() => {
                this.buttonCopied = true;

                setTimeout(this.resetCopy, 3000);
            });
        }
    }
}
</script>

<style lang="scss" scoped>
.custom-pricing::v-deep {
    &.full-height,
    .full-height {
        height: 100%;
    }

    .top-border {
        border-top: $faded-blue 6px solid;
    }

    .link {
        display: flex;
        align-items: center;

        a {
            display: block;
            max-width: 500px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }

    .form-row {
        > div {
            display: flex;
            align-items: center;
        }
    }

    .v-input--checkbox {
        margin-top: 0;
    }

    .v-text-field {
        max-width: fit-content;
        margin: 0 8px;
    }
}
</style>
